import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { useNavigate } from 'react-router-dom'
// @ts-ignore 
import logo from '../images/utilities/logo.png'

function Navigazione() {
  let navigate = useNavigate()
  return (
    <>
    <Navbar className="sticky-top" collapseOnSelect expand="lg" bg="primary" variant="dark">
      <Container fluid>
        <Navbar.Brand onClick={() => navigate('/')} className="pointer">
          <img alt="Colonia Viberti" src={logo} height="40" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => navigate('/')}>Home</Nav.Link>
            <NavDropdown title="Immagini" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={() => navigate('/immagini/interni')}>Interni</NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate('/immagini/esterni')}>Esterni</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => navigate('/immagini/dintorni')}>Dintorni</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={() => navigate('/come-arrivare')}>Come arrivare</Nav.Link>
            <Nav.Link onClick={() => navigate('/contattaci')}>Contattaci</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  )
}

export default Navigazione;