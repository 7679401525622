import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Fade from 'react-bootstrap/Fade'
import Alert from 'react-bootstrap/Alert'
import { homeData } from '../data/datas'

function Home() {
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Colonia Viberti'
    setOpen(true)
  }, [])

  return (
    <Container>
        <br/>

        <h1>La Colonia</h1>
        <hr/>

        <br/>

        <Container fluid>

          <Alert variant="primary">⚠️ La Colonia è destinata a gruppi di almeno venti persone, ed è aperta solo nel periodo estivo, da giugno a settembre.</Alert>

          {homeData.map((item) => {
            if ((item.index%2) === 0) {
              return (
                <div key={item.index}>
                <Row>
                  <Col sm={8} className="mt-3 mb-3">
                    {item.testo}
                  </Col>
                  <Col>
                    <Fade in={open} timeout={500}>
                      <Image className="img pointer" src={item.imms} width={300} fluid rounded onClick={() => {window.open(item.imm)}} alt={item.alt} />
                    </Fade>
                  </Col>
                </Row>
  
                <br/><br/>
                </div>
              )
            } else {
              return (
                <div key={item.index}>
                <Row>
                  <Col>
                    <Fade in={open} timeout={500}>
                      <Image className="img pointer" src={item.imms} width={300} fluid rounded onClick={() => {window.open(item.imm)}} alt={item.alt} />
                    </Fade>
                  </Col>
                  <Col sm={8} className="mt-3 mb-3">
                    {item.testo}
                  </Col>
                </Row>
                <br/><br/>
                </div>
              ) 
            }
          })}

          <br/><br/>

        </Container>
    </Container>
  )
}

export default Home;
