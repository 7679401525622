import React, { useEffect, useState }from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'

function Amministrativa() {
  const [password, setPassword] = useState<String>('')
  const [accesso, setAccesso] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Colonia Viberti - area riservata'
  }, [])

  const submitForm = (e: {preventDefault: Function}) => {
    e.preventDefault()
    if (password === 'amministrativa') {
      setAccesso(true)
    } else {
      setAccesso(false)
    }
  }

  return (
    <Container>
      <br/>
      
      <h1>Area amministrativa</h1>
      <hr/>

      <Container fluid hidden={accesso}>

        <p>Inserisci la password per accedere all'area riservata dedicata ai responsabili.</p>

        <Form onSubmit={(e) => {submitForm(e)}}>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" required value={password.toString()} onChange={(e) => {setPassword(e.target.value)}} />
          </Form.Group>

          <Button variant="outline-primary" type="submit">Accedi</Button>
        </Form>

        <br/>

        <small className="text-muted">
          Non condividere la password.
        </small>
      </Container>

      <Container fluid hidden={!accesso}>
        <p>Benvenuto nell'area riservata ai responsabili. Da qui puoi scaricare quello che ti serve. (da finire)</p>

        <Container fluid><br/>

          <h3>Modulistica</h3>

          <Table responsive>
            <thead>
              <tr>
                <th>Documento</th>
                <th>Data</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Modello costi soggiorno</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Modulistica/costisoggiorno.pdf" target="_blank">Scarica</Button></td>
              </tr>
              <tr>
                <td>Modello persone alloggiate</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Modulistica/personealloggiate.pdf" target="_blank">Scarica</Button></td>
              </tr>
              <tr>
                <td>Modello assunzione responsabilità</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Modulistica/assunzioneresponsabilita.pdf" target="_blank">Scarica</Button></td>
              </tr>
              <tr>
                <td>Modello controllo legionella</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Modulistica/controllolegionella.pdf" target="_blank">Scarica</Button></td>
              </tr>
            </tbody>
          </Table>

          <br/>
          

          <h3>Manuali</h3>

          <Table responsive>
            <thead>
              <tr>
                <th>Documento</th>
                <th>Data</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Manuale manutentori</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Manuali/manuale.pdf" target="_blank">Scarica</Button></td>
              </tr>
              <tr>
                <td>Manuale HACCP</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Manuali/manualeHACCP.pdf" target="_blank">Scarica</Button></td>
              </tr>
            </tbody>
          </Table>

          <br/>

          <h3>Autorizzazioni</h3>

          <Table responsive>
            <thead>
              <tr>
                <th>Documento</th>
                <th>Data</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>SCIA 2019 esercizio attività</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Autorizzazioni/scia2019.pdf" target="_blank">Scarica</Button></td>
              </tr>
              <tr>
                <td>Autorizzazione scarico acque reflue 1990</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Autorizzazioni/scaricoacque.pdf" target="_blank">Scarica</Button></td>
              </tr>
              <tr>
                <td>Potabilità 2023</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Autorizzazioni/potabilita2023.pdf" target="_blank">Scarica</Button></td>
              </tr>
              <tr>
                <td>Controllo legionella 2023</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Autorizzazioni/controllolegionella2023.pdf" target="_blank">Scarica</Button></td>
              </tr>
            </tbody>
          </Table>

          <br/>

          <h3>Documentazione</h3>

          <Table responsive>
            <thead>
              <tr>
                <th>Documento</th>
                <th>Data</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Comodato comune di Rivoli</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Documentazione/comodato.pdf" target="_blank">Scarica</Button></td>
              </tr>
              <tr>
                <td>Planimetrie</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Documentazione/planimetrie.pdf" target="_blank">Scarica</Button></td>
              </tr>
              <tr>
                <td>Procedura soggiorno</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Documentazione/procedurasoggiorno.pdf" target="_blank">Scarica</Button></td>
              </tr>
              <tr>
                <td>Regolamento soggiorno</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Documentazione/regolamento.pdf" target="_blank">Scarica</Button></td>
              </tr>
              <tr>
                <td>Convenzione soggiorno</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Documentazione/convenzione.pdf" target="_blank">Scarica</Button></td>
              </tr>
              <tr>
                <td>Lettera contratto ospiti</td>
                <td>01/09/2023</td>
                <td><Button variant="outline-primary" href="./documenti/Documentazione/letteracontratto.pdf" target="_blank">Scarica</Button></td>
              </tr>
            </tbody>
          </Table>

          <br/><br/>

        </Container>

      </Container>

    </Container>
  )
}

export default Amministrativa;
