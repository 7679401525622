// esterni
import esterni1 from '../images/esterni/esterni1.webp'
import esterni2 from '../images/esterni/esterni2.webp'
import esterni3 from '../images/esterni/esterni3.webp'
import esterni4 from '../images/esterni/esterni4.webp'
import esterni5 from '../images/esterni/esterni5.webp'
import esterni6 from '../images/esterni/esterni6.webp'
import esterni7 from '../images/esterni/esterni7.webp'
import esterni8 from '../images/esterni/esterni8.webp'
import esterni9 from '../images/esterni/esterni9.webp'
import esterni11 from '../images/esterni/esterni11.webp'
import esterni12 from '../images/esterni/esterni12.webp'
import esterni1s from '../images/esterni/esterni1s.webp'
import esterni2s from '../images/esterni/esterni2s.webp'
import esterni9s from '../images/esterni/esterni9s.webp'
// interni
import interni2 from '../images/interni/interni2.webp'
import interni4 from '../images/interni/interni4.webp'
import interni5 from '../images/interni/interni5.webp'
import interni6 from '../images/interni/interni6.webp'
import interni7 from '../images/interni/interni7.webp'
// dintorni
import dintorni1 from '../images/dintorni/dintorni1.webp'
import dintorni2 from '../images/dintorni/dintorni2.webp'
import dintorni3 from '../images/dintorni/dintorni3.webp'
import dintorni4 from '../images/dintorni/dintorni4.webp'
import dintorni5 from '../images/dintorni/dintorni5.webp'
import dintorni6 from '../images/dintorni/dintorni6.webp'
import dintorni7 from '../images/dintorni/dintorni7.webp'
import dintorni8 from '../images/dintorni/dintorni8.webp'
import dintorni9 from '../images/dintorni/dintorni9.webp'
import dintorni10 from '../images/dintorni/dintorni10.webp'
import dintorni11 from '../images/dintorni/dintorni11.webp'
import dintorni12 from '../images/dintorni/dintorni12.webp'
import dintorni13 from '../images/dintorni/dintorni13.webp'
// bivi
import bivio1 from '../images/bivi/bivio1.webp'
import bivio2 from '../images/bivi/bivio2.webp'
import bivio3 from '../images/bivi/bivio3.webp'


export const homeData = [
    {
        index: 0,
        imm: esterni2,
        imms: esterni2s,
        alt: "Casa",
        testo: "La Colonia Alpina Viberti e’ composta da tre edifici: due di dimensioni maggiori con tipica conformazione a lunga manica, ed un terzo più piccolo che da sempre ha svolto il ruolo di alloggio del custode. Accanto ai due fabbricati principali si apre un vasto prato, utilizzato come campo sportivo. I locali utilizzati dagli ospiti sono quelli dei due edifici principali. I due fabbricati sono diversi solo per la distribuzione interna degli ambienti, suddivisi essenzialmente in locali destinati alle attività diurne e locali per il pernottamento."
    },
    {
        index: 1,
        imm: esterni9,
        imms: esterni9s,
        alt: "Vista",
        testo: "L’edificio A ospita gli ambienti comuni utilizzati per le attività diurne. Anche in questo caso le suddivisioni interne sono piuttosto simmetriche, con un alternanza di due locali più vasti alternati a tre blocchi di dimensioni più ridotte. Al centro, una stanza e’ stata adibita a cappella per la preghiera. All’estremità nord-occidentale si trovano la cucina ed il refettorio, fra loro comunicanti. Sul lato opposto invece la Sala giochi ed un blocco di servizi contenente i bagni, l’infermeria e due piccole camerette. La struttura è dotata di telefono pubblico."
    },
    {
        index: 2,
        imm: esterni1,
        imms: esterni1s,
        alt: "Casa",
        testo: "L’edificio B ospita le zone utilizzate essenzialmente nelle ore serali e notturne, cioè le camere da letto ed i servizi igienici. È articolato in due sezioni identiche e speculari, destinabili l’una agli uomini, l’altra alle donne. Ogni sezione è composta da due parti: la più estesa e’ la camerata vera e propria, che occupa la manica dell’edificio per l’intera larghezza e può ospitare oltre 20 persone; vi sono poi due piccole camere da letto per 2-4 persone oltre a bagni e doccie riservati. Il totale della capienza dell’edificio A (uomini + donne) è di 72 persone."
    }
]

export const immaginiIntData = [
    {
        index: 0,
        titolo: "Refettorio", 
        descrizione: "Il refettorio della casa principale", 
        url: interni6
    },
    {
        index: 1,
        titolo: "Sala giochi", 
        descrizione: "Una delle stanze della casa, la sala giochi", 
        url: interni4
    },
    {
        index: 2,
        titolo: "Camerata", 
        descrizione: "Una delle due camerate speculari", 
        url: interni2
    },
    {
        index: 4,
        titolo: "Cucina", 
        descrizione: "La cucina della casa", 
        url: interni7
    },
    {
        index: 5,
        titolo: "Cappella", 
        descrizione: "La cappella della casa", 
        url: interni5
    }
]

export const immaginiEstData = [
    {
        index: 0,
        titolo: "", 
        descrizione: "", 
        url: esterni1
    },
    {
        index: 1,
        titolo: "", 
        descrizione: "", 
        url: esterni2
    },
    {
        index: 2,
        titolo: "", 
        descrizione: "", 
        url: esterni3
    },
    {
        index: 3,
        titolo: "", 
        descrizione: "", 
        url: esterni4
    },
    {
        index: 4,
        titolo: "", 
        descrizione: "", 
        url: esterni5
    },
    {
        index: 5,
        titolo: "", 
        descrizione: "", 
        url: esterni6
    },
    {
        index: 6,
        titolo: "", 
        descrizione: "", 
        url: esterni7
    },
    {
        index: 7,
        titolo: "", 
        descrizione: "", 
        url: esterni8
    },
    {
        index: 8,
        titolo: "", 
        descrizione: "", 
        url: esterni11
    },
    {
        index: 9,
        titolo: "", 
        descrizione: "", 
        url: esterni12
    }
]

export const immaginiDinData = [
    {
        index: 0,
        titolo: "", 
        descrizione: "", 
        url: dintorni1
    },
    {
        index: 1,
        titolo: "", 
        descrizione: "", 
        url: dintorni2
    },
    {
        index: 2,
        titolo: "", 
        descrizione: "", 
        url: dintorni3
    },
    {
        index: 3,
        titolo: "", 
        descrizione: "", 
        url: dintorni4
    },
    {
        index: 4,
        titolo: "", 
        descrizione: "", 
        url: dintorni5
    },
    {
        index: 5,
        titolo: "", 
        descrizione: "", 
        url: dintorni6
    },
    {
        index: 6,
        titolo: "", 
        descrizione: "", 
        url: dintorni7
    },
    {
        index: 7,
        titolo: "", 
        descrizione: "", 
        url: dintorni8
    },
    {
        index: 8,
        titolo: "", 
        descrizione: "", 
        url: dintorni9
    },
    {
        index: 9,
        titolo: "", 
        descrizione: "", 
        url: dintorni10
    },
    {
        index: 10,
        titolo: "", 
        descrizione: "", 
        url: dintorni11
    },
    {
        index: 11,
        titolo: "", 
        descrizione: "", 
        url: dintorni12
    },
    {
        index: 12,
        titolo: "", 
        descrizione: "", 
        url: dintorni13
    }
]

export const raggiungerciBivi = [
        {
            index: 0,
            alt: "Bivio",
            testo: "Dalla S.S. 24 dopo il Comune di Exilles in direzione Bardonecchia prendere il bivio a destra per Eclause/Grange della Valle",
            img: bivio1
        },
        {
            index: 1,
            alt: "Bivio",
            testo: "Seguite la strada fino al bivio per Eclause, e svoltate a destra",
            img: bivio2
        },
        {
            index: 2,
            alt: "Bivio",
            testo: "Proseguite lungo la strada continuando a salire verso la colonia.",
            img: bivio3
        },
]