import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { raggiungerciBivi } from '../data/datas'

function Raggiungerci() {
  useEffect(() => {
    document.title = 'Colonia Viberti - come arrivare'
  }, [])
  
  return (
    <Container>
      <br/>

      <h1>Come arrivare</h1>
      <hr/>

      <Container fluid>
        <h2>Mappa</h2>
        <iframe title="Google maps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2379.395982302095!2d6.9000472!3d45.1125609!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4789b897ffae4d7d%3A0xd14c646aa4e1110a!2sColonia%20Alpina%20Candido%20Viberti!5e1!3m2!1sen!2sit!4v1662031463209!5m2!1sen!2sit" width="100%" height={450} referrerPolicy="no-referrer-when-downgrade" />        
        <p className="pointer" onClick={() => {navigator.clipboard.writeText('45.112966, 6.899521')}}>45.112966, 6.899521</p>
        
        <hr/>
        <br/>
           
        <h2>In macchina</h2>
          
        <p>
          Dalla SS 24 della strada alle Grange della Valle sono 8km di strada di montagna asfaltata. <br/><br/>
          - Da Torino – Susa:
        </p>
 
        {raggiungerciBivi.map((item) => {
          return(
            <div key={item.index}>
              <Row>
                <Col>
                  <p>{item.testo}</p>
                </Col>
                <Col>
                  <Image className="pointer img" src={item.img} width={300} fluid rounded onClick={() => {window.open(item.img)}} alt={item.alt} />
                </Col>
              </Row>

              <br/><br/>
            </div>
          )
        })}
          
        <br/> 

        <p>
          - Da Bardonecchia – Oulx: <br/>
          Dalla S.S. 24 dopo il Comune di Salbertrand proseguire lungo i tornanti e al termine della salita svoltare a sinistra direzione Eclause/Grange della Valle.
        </p>

        <br/>
        <hr/>

        <h2>In pullman</h2>
        <p>La Colonia è anche raggiungibile con pullman di massimo 28 posti e di lunghezza non superiore ai 7,80 m.</p>

        <br/>
        <hr/>

        <h2>In treno</h2>
        <p>Un'altra possibilità è prendere il treno fino alla stazione di Salbertrand, con possibilità di prenotare o un taxi o un servizio navetta, oppure proseguire a piedi.</p>

        <br/>
        <hr/>

        <h2>A piedi</h2>
        <p>
          - Dalla stazione ferroviaria di Salbertrand: <br/>
          seguendo il tratto della GTA n. 541 fino al paese di Eclause e da lì sulla SP 258 fino a Grange della Valle (tempo di percorrenza: 2,30 ore circa). <br/><br/>
          - Da Exilles: <br/>
          imboccare dalla SS 24 il sentiero vicino al Torrente Galambra; da qui si raggiungono inizialmente le Grange Margheria si prosegue fino alle Grange Clot Sesian; incrociata la SP 258 si giunge fino alle Grange della Valle (tempo di percorrenza: 2,30 circa).
        </p>

        <br/><br/>
        
      </Container>
    </Container>
  )
}

export default Raggiungerci;