import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
// @ts-ignore 
import NotFound from '../images/utilities/notfound.webp'

function Error() {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (location.pathname === '/coloniaviberti/' || location.pathname === '/coloniaviberti') {
      navigate('/')
    }
    if (location.pathname === '/coloniaviberti/come-arrivare/' || location.pathname === '/coloniaviberti/come-arrivare') {
      navigate('/come-arrivare')
    }
    if (location.pathname === '/coloniaviberti/galleria-interni/' || location.pathname === '/coloniaviberti/galleria-interni') {
      navigate('/immagini/interni')
    }
    if (location.pathname === '/coloniaviberti/galleria-esterni/' || location.pathname === '/coloniaviberti/galleria-esterni') {
      navigate('/immagini/esterni')
    }
  }, [location, navigate])
  
  return (
    <Container>
      <br/>
      <h1>Pagina non trovata...</h1>
      <hr/>
      <Image src={NotFound} width="55%" fluid className="mt-0" />
    </Container>
  )
}

export default Error;
