import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Carousel from 'react-bootstrap/Carousel'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import { immaginiIntData, immaginiEstData, immaginiDinData } from '../data/datas'

interface immaginiType {
  index: number
  titolo: string | undefined
  descrizione: string | undefined
  url: string | undefined
}

function Immagini() {
  let params = useParams()
  let navigate = useNavigate()
  const scrollRef = useRef<any>(null)
  const [immagini, setImmagini] = useState<immaginiType[]>([{index: 0, titolo: undefined, descrizione: undefined, url: 'http://whiteimages.com.au/wp-content/uploads/2017/08/White-Images_Solid-White.png'}])

  useEffect(() => {
    if (params.quale === 'interni') {
      setImmagini(immaginiIntData)
    } else if (params.quale === 'esterni') {
      setImmagini(immaginiEstData)
    } else {
      setImmagini(immaginiDinData)
    }

    document.title = 'Colonia Viberti - immagini'
  }, [params])

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView()
    }
  }, [scrollRef])
  

  const items = immagini.map((imm) => {
    return (
      <Carousel.Item key={imm.index}>
        <Image className="d-block w-100 img-fluid" src={imm.url} alt={imm.titolo} rounded	thumbnail />
        <Carousel.Caption>
          <h1>{imm.titolo}</h1>
          <p>{imm.descrizione}</p>
        </Carousel.Caption>
      </Carousel.Item>
    )
  })
  

  return (
    <Container>
      <br/>

      <h1 className="pointer" onClick={() => {params.quale === 'interni' ? navigate('/immagini/esterni') : (params.quale === 'esterni' ? navigate('/immagini/dintorni') : navigate('/immagini/interni'))}}>Immagini {params.quale}</h1>
      <hr/>

      <Container fluid>
        <Carousel>
          {items}
        </Carousel>
      </Container>

      <div ref={scrollRef} />

      <br/><br/>

    </Container>
  )
}

export default Immagini;
