import React, { useEffect, useState }from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'

function Documenti() {
  const [password, setPassword] = useState<String>('')
  const [accesso, setAccesso] = useState<boolean>(false)

  useEffect(() => {
    document.title = 'Colonia Viberti - documenti'
  }, [])

  const submitForm = (e: {preventDefault: Function}) => {
    e.preventDefault()
    if (password === 'ospiti') {
      setAccesso(true)
    } else {
      setAccesso(false)
    }
  }

  return (
    <Container>
      <br/>
      
      <h1>Area documenti ospiti</h1>
      <hr/>

      <Container fluid hidden={accesso}>

        <p>Inserisci la password per scaricare i documenti che è necessario compilare per la vostra permanenza alla Colonia.</p>

        <Form onSubmit={(e) => {submitForm(e)}}>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" required value={password.toString()} onChange={(e) => {setPassword(e.target.value)}} />
          </Form.Group>

          <Button variant="outline-primary" type="submit">Accedi</Button>
        </Form>

        <br/><br/>

        <small className="text-muted">
          Non condividere la password.
        </small>
      </Container>

      <Container fluid hidden={!accesso}>
        <p>Da qui puoi scaricare i documenti che è necessario compilare per la vostra permanenza alla Colonia.</p>

        <Table responsive>
          <thead>
            <tr>
              <th>Documento</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Convenzione</td>
              <td><Button variant="outline-primary" href="./documenti/Modulistica/convenzione.pdf" target="_blank">Scarica</Button></td>
            </tr>
            <tr>
              <td>Lettera contratto</td>
              <td><Button variant="outline-primary" href="./documenti/Modulistica/letteracontratto.pdf" target="_blank">Scarica</Button></td>
            </tr>
            <tr>
              <td>Manuale HACCP</td>
              <td><Button variant="outline-primary" href="./documenti/Modulistica/manualeHACCP.pdf" target="_blank">Scarica</Button></td>
            </tr>
            <tr>
              <td>Persone alloggiate</td>
              <td><Button variant="outline-primary" href="./documenti/Modulistica/personealloggiate.pdf" target="_blank">Scarica</Button></td>
            </tr>
            <tr>
              <td>Procedura soggiorno</td>
              <td><Button variant="outline-primary" href="./documenti/Modulistica/procedurasoggiorno.pdf" target="_blank">Scarica</Button></td>
            </tr>
            <tr>
              <td>Regolamento</td>
              <td><Button variant="outline-primary" href="./documenti/Modulistica/regolamento.pdf" target="_blank">Scarica</Button></td>
            </tr>
            <tr>
              <td>Liberatoria tre</td>
              <td><Button variant="outline-primary" href="./documenti/Modulistica/scia2019.pdf" target="_blank">Scarica</Button></td>
            </tr>
          </tbody>
        </Table>

      </Container>

    </Container>
  )
}

export default Documenti;
