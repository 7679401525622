import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './style.css'
import Home from './components/Home'
import Contatti from './components/Contatti'
import Immagini from './components/Immagini'
import Raggiungerci from './components/Raggiungerci'
import Error from './components/Error'
import Navigazione from './components/Navigazione'
import Documenti from './components/Documenti'
import Riservato from './components/Riservato'
import Amministrativa from './components/Amministrativa'
import Container from 'react-bootstrap/Container'

function App() {
  return (
    <>
      <BrowserRouter>
        <Navigazione/>

        <Container fluid>
          <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="immagini/:quale" element={<Immagini/>} />
              <Route path="come-arrivare" element={<Raggiungerci/>} />
              <Route path="contattaci" element={<Contatti/>} />
              <Route path="documenti" element={<Documenti/>} />
              <Route path="riservato" element={<Riservato/>} />
              <Route path="amministrativa" element={<Amministrativa/>} />
              <Route path="*" element={<Error/>} />
          </Routes>
        </Container>

        <small className="fixed-bottom text-muted ms-2" >
          Colonia Viberti © Copyright 2024, tutti i diritti riservati • Questo sito utilizza cookie tecnici di terze parti.
        </small>
      </BrowserRouter>
    </>
  )
}

export default App;
