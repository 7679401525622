import React, { useEffect, useState }from 'react'
import { useNavigate } from 'react-router-dom'
import emailjs from '@emailjs/browser'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

interface dataType {
  nome: string,
  email: string,
  oggetto: string,
  messaggio: string
}

function Contatti() {
  const [data, setData] = useState<dataType>({nome: '', email: '', oggetto: '', messaggio: ''})
  let navigate = useNavigate()

  useEffect(() => {
    document.title = 'Colonia Viberti - contatti'
  }, [])

  const submitForm = (e: {preventDefault: Function}) => {
    e.preventDefault()

    if (data.nome !== '' && data.email !== '' && data.oggetto !== '' && data.messaggio !== '') {
      emailjs.send('service_50r2kyb', 'template_s3dkz23',{
        nome: data.nome,
        email: data.email,
        oggetto: data.oggetto,
        testo: data.messaggio
      }, '1M8kl52TjoIGTXOFV')
    }

    navigate('/')
  }

  return (
    <Container>
      <br/>
      
      <h1>Contattaci</h1>
      <hr/>

      <Container fluid>

        <p>Inserisci i tuoi dati se vuoi chiedere delle informazioni, sarai contattato al più presto.</p>

        <Form onSubmit={(e) => {submitForm(e)}}>
          <Form.Group className="mb-3">
            <Form.Label>Nome</Form.Label>
            <Form.Control type="text" placeholder="Nome" required value={data.nome} onChange={(e) => {setData({nome: e.target.value.toString(), email: data.email, oggetto: data.oggetto, messaggio: data.messaggio})}} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Indirizzo email</Form.Label>
            <Form.Control type="email" placeholder="Email" required value={data.email} onChange={(e) => {setData({nome: data.nome, email: e.target.value.toString(), oggetto: data.oggetto, messaggio: data.messaggio})}} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Oggetto</Form.Label>
            <Form.Control type="text" placeholder="Oggetto" value={data.oggetto} required onChange={(e) => {setData({nome: data.nome, email: data.email, oggetto: e.target.value.toString(), messaggio: data.messaggio})}} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Messaggio</Form.Label>
            <Form.Control as="textarea" rows={2} placeholder="Messaggio" required value={data.messaggio} onChange={(e) => {setData({nome: data.nome, email: data.email, oggetto: data.oggetto, messaggio: e.target.value.toString()})}} />
          </Form.Group>

          <Button variant="outline-primary" type="submit">Invia</Button>
        </Form>
      </Container>

      <br/><br/>

      <p className="pointer text-muted small text-end" onClick={() => navigate('/documenti')}>
        Area documenti ospiti
      </p>
      <p className="pointer text-muted small text-end" onClick={() => navigate('/riservato')}>
        Area riservata custodi
      </p>
      <p className="pointer text-muted small text-end" onClick={() => navigate('/amministrativa')}>
        Area amministrativa
      </p>

      <br/><br/>

    </Container>
  )
}

export default Contatti;
